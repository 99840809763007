import React from 'react';
import Helmet from 'react-helmet';

import LandingHeader from '../../../components/Landing/Section/Header';
import HeroSection from '../../../components/Landing/Section/Hero';
import TestimonialSection from '../../../components/Landing/Section/Testimonial';
import '../../../assets/css/landing.css';

import DecoristImage from '../../../assets/images/landing/decorist/decorist-section-image.svg';
import AboutSection from '../../../components/Landing/Section/About';
import ClientLogosSection from '../../../components/Landing/Section/ClientLogos/lndex';
import LandingFooter from '../../../components/Landing/Section/Footer';
import PartnershipSection from '../../../components/Landing/Section/PartnershipTwo';

const themeColor = '#1AA5F8';

const Hero = {
  title: (
    <div>
      <strong>Interior Design App For Photorealistic Rendering</strong>
    </div>
  ),
  subTitle: "Reducing the processing time to boost designer's productivity",
  bannerSrc: require('../../../assets/images/landing/decorist/decorist-banner.svg').default,
  logoSrc: require('../../../assets/images/landing/decorist/decorist-logo.svg').default,
};

const Testimonial = {
  titleLogoSrc: require('../../../assets/images/landing/decorist/decorist-x-tintash.svg').default,
  details: [
    <div>
      First design iteration delivery time reduced from{' '}
      <span className="text-blue">2 days to 2 hours.</span>
    </div>,
    <div>
      <span className="text-blue">
        Eliminated designers’ dependency on 3D artists to design the room.
      </span>{' '}
      They could now design the room using the RoomBuilder app.
    </div>,
    <div>
      <span className="text-blue">Got acquired by Fortune 500</span> - Bed, Bath and Beyond.
    </div>,
  ],
  testimonial: {
    quote:
      'Tintash became a truly innovative thought partner; they didn’t just do what we asked for but also helped us conceive new technologies and tools to build.',
    name: 'Emily Fisher',
    position: (
      <div>
        Head of Product & Design <br />
        Decorist
      </div>
    ),
    image: require('../../../assets/images/landing/decorist/decorist-client.svg').default,
  },
};

function Index() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landing | Decorist</title>
      </Helmet>
      <div className="landing-wrapper">
        <LandingHeader />
        <HeroSection
          title={Hero.title}
          subTitle={Hero.subTitle}
          bannerSrc={Hero.bannerSrc}
          logoSrc={Hero.logoSrc}
        />
        <TestimonialSection
          titleLogoSrc={Testimonial.titleLogoSrc}
          details={Testimonial.details}
          testimonial={Testimonial.testimonial}
          themeColor={themeColor}
        />
        <img src={DecoristImage} alt="decorist" />
        <PartnershipSection />
        <AboutSection />
        <ClientLogosSection />
        <LandingFooter />
      </div>
    </>
  );
}

export default Index;
