// extracted by mini-css-extract-plugin
export var button = "Partnership-module--button--Tuefu";
export var button2 = "Partnership-module--button2--Y88pn";
export var buttonContainer = "Partnership-module--buttonContainer--ZOvmg";
export var buttonContainerMain = "Partnership-module--buttonContainerMain--gHpfZ";
export var container = "Partnership-module--container--GtPZE";
export var description = "Partnership-module--description--rpRXc";
export var image = "Partnership-module--image--8hSom";
export var imageContainer = "Partnership-module--imageContainer--pfgxm";
export var sectionTitle = "Partnership-module--sectionTitle--JrdXr";
export var textContainer = "Partnership-module--textContainer--6hSh+";
export var title = "Partnership-module--title--c5KsZ";
export var wrapper = "Partnership-module--wrapper--Gb5rr";