import React from 'react';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {Event} from '../../../GAClickEvent';

import DecoristOne from '../../../../assets/images/landing/decorist/decorist-1.svg';
import DecoristTwo from '../../../../assets/images/landing/decorist/decorist-2.svg';
import DecoristThree from '../../../../assets/images/landing/decorist/decorist-3.svg';
import DecoristFour from '../../../../assets/images/landing/decorist/decorist-4.svg';

import * as classes from './Partnership.module.css';

const PartnershipSection = () => (
  <div className={classes.wrapper}>
    <div className={clsx(classes.container, 'flex-column')}>
      <h1 className={clsx(classes.sectionTitle, 'mb-3')}>Business Problem</h1>
      <p className={clsx(classes.description, 'mb-3')}>
        Decorist initially had a manual workflow between artists and designers to create a room
        design and share with the clients. The process was inefficient and required designers to
        depend on 3D artists to create designs. 3DS Max was too complex for designers and had a
        steep learning curve, so Decorist was looking to build an app that could empower designers
        to design the room themselves.
      </p>
    </div>
    <div className={clsx(classes.container, 'flex-column')}>
      <h1 className={clsx(classes.sectionTitle, 'mb-3')}>Decorist and Tintash Partnership</h1>
      <p className={clsx(classes.description, 'mb-3')}>
        Decorist engaged Tintash to develop the Room Builder application. They wanted to automate
        the whole process of generating photorealistic renders and make it faster and more efficient
        through a user-friendly app for their designers.
      </p>
    </div>
    <div className={clsx(classes.container, 'flex-column-reverse flex-lg-row')}>
      <div className={clsx(classes.textContainer, 'pr-lg-5')}>
        <h2 className={classes.title}>Research and Development for Technical Challenges</h2>
        <p className={clsx(classes.description)}>
          Tintash started by solving tricky technical problems. They focused on making things like
          automation and realistic graphics work smoothly. Working closely with Decorist, they
          figured out how the app should look and function. They tested it a lot to make sure it
          worked well for users.
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={DecoristOne} alt="decorist" />
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column flex-lg-row')}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={DecoristTwo} alt="decorist" />
      </div>
      <div className={clsx(classes.textContainer, 'pl-lg-5')}>
        <h2 className={classes.title}> Product and Technical Design</h2>
        <p className={clsx(classes.description)}>
          Together with Decorist, Tintash made the app look great and work smoothly. They planned
          how everything should look on the screen. They also made guidelines for artists creating
          3D stuff for the app. Plus, they built a system to keep track of all the 3D models and
          info needed.
        </p>
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column-reverse flex-lg-row')}>
      <div className={clsx(classes.textContainer, 'pr-lg-5')}>
        <h2 className={classes.title}> Asset Workflow and Quality Assurance</h2>
        <p className={classes.description}>
          Tintash made sure all the 3D things in the app worked together well. They used a tool
          called Slack to watch over the picture-making process in real-time. Also, they made the
          computers work better and faster for making these pictures.
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={DecoristThree} alt="decorist" />
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column flex-lg-row')}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={DecoristFour} alt="decorist" />
      </div>
      <div className={clsx(classes.textContainer, 'pl-lg-5')}>
        <h2 className={classes.title}>Optimization and Final Product Delivery</h2>

        <p className={clsx(classes.description)}>
          Tintash focused on making everything better before finishing. They made the 3D models
          smaller and checked them a lot to make sure they looked good. In the end, they made the
          app much faster. Now, making a room and getting pictures used to take two days, but now it
          only takes a couple of hours.
        </p>
      </div>
    </div>
    <div className={clsx(classes.buttonContainerMain)}>
      <div className={classes.buttonContainer}>
        <Link
          onClick={() =>
            Event('Case Study', 'Case Study Button is clicked', 'Decorist Landing Page')
          }
          to="/portfolio/decorist-room-builder"
        >
          <button type="button" className={classes.button}>
            Read Full Case Study
          </button>
        </Link>
      </div>

      <div className={classes.buttonContainer}>
        <Link
          onClick={() => Event('Portfolio', 'Portfolio button is clicked', 'Decorist Landing Page')}
          to="/portfolio"
        >
          <button type="button" className={classes.button2}>
            Explore Our Portfolio
          </button>
        </Link>
      </div>
    </div>
  </div>
);

export default PartnershipSection;
